.artist-cell {
  white-space: break-spaces;
}

.dimmed.dimmable > .ui.animating.dimmer, .dimmed.dimmable > .ui.visible.dimmer, .ui.active.dimmer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ui.modal,
.ui.active.modal {
  margin: 0 auto!important;
/*  top: auto !important;*/
  left: auto !important;
  transform-origin: center !important;
  transition: all ease .5s;
  height: auto;
}

body {
  background: #1b1c1d;
  color: #f0f0f0;
  zoom: 90%;
}

h1 {
  color: orange;
}

.ui.secondary.inverted.pointing.menu .header.item {
  color: orange !important;
}

.modal b {
	color: rgba(0,0,0,.87);
}

.ui.ribbon.label {
	font-weight: bolder;
}

td {
  white-space: break-spaces;
}

.ui.celled.table tr td:first-child, .ui.celled.table tr th:first-child {
  border-left: 1px solid rgba(34,36,38,.1);
}

.active.tab {
  background: rgba(255,255,255,.15);
}

.browse-table-row {
  cursor: pointer;
}

.merchandise_open {
  background-color: rgba(3,3,3, 10.4) !important;
  border-left: white solid 20px !important;
  border-right: white solid 20px !important;
  border-bottom: white solid 10px !important;
  border-top: none !important;
  padding: 10px;
}

.ui.inverted.segment.merchandise_open {
  background-color: rgba(3,3,3, 10.4) !important;
  background: rgba(3,3,3, 10.4) !important;
  border: none !important;
  margin-left: 10px;
}

.modal_references,
.modal_references .content,
.modal_references .header {
  background: #333 !important;
  color: rgba(255,255,255,.9) !important;
  text-align: -webkit-center;
}

.btn-link {
  background: none;
  border: none;
  text-decoration: auto;
}

.default_height_500 {
  min-height: 500px;
}

.ui.attached.tabular.menu {
  overflow-x: overlay;
  overflow-y: hidden;
}

.ui.inverted.definition.table tfoot:not(.full-width) th:first-child {
  background-color: unset !important;
  box-shadow: unset !important;
}

.react-photo-album img {
  max-width: 300px;
}

.yarl__slide_description {
  overflow: inherit !important;
}

/*.ui.inverted.pagination.menu {
  margin-right: 200px;
}
*/
.react-photo-album {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.browse-table-segment {
  overflow-x: auto;
}

.ui.secondary.inverted.pointing.menu {
  flex-wrap: wrap;
}

.width100 {
  width: 100%;
}

.merchandise_open .ui.grid .row {
  margin: 10px;
}

.ui.icon.negative.message .header {
  margin-right: 5%;
}

.variantFilter p {
  display: inline-flex;
  margin-right: 20px;
  color: white;
  font-style: italic;
}

.variantFilter .button {
  margin-right: 20px;
}

@media only screen and (max-width: 767px) {
  .ui.table:not(.unstackable) tr {
    display: table-row !important;
  }
  .ui.table:not(.unstackable) tr>td, .ui.table:not(.unstackable) tr>th {
    display: table-cell !important;
    padding: .78571429em .78571429em !important;
  }
  .ui.table:not(.unstackable) thead {
    display: table-header-group !important;
  }
  .ui.table:not(.unstackable) tbody {
    display: table-row-group !important;
  }
}
@media only screen and (max-width: 550px) {
  .ui.table:not(.unstackable) tr, .ui.table:not(.unstackable) tr>td, .ui.table:not(.unstackable) tr>th {
    display: block !important;
    border: none !important;
    padding: .25em .75em !important;
    box-shadow: none !important;
  }
}


@media only screen and (max-width: 767px) {
  .ui.grid>.stackable.stackable.row>.column, .ui.stackable.grid>.column.grid>.column, .ui.stackable.grid>.column.row>.column, .ui.stackable.grid>.column:not(.row), .ui.stackable.grid>.row>.column, .ui.stackable.grid>.row>.wide.column, .ui.stackable.grid>.wide.column {
    width: 50% !important;
  }
}
@media only screen and (max-width: 550px) {
  .ui.grid>.stackable.stackable.row>.column, .ui.stackable.grid>.column.grid>.column, .ui.stackable.grid>.column.row>.column, .ui.stackable.grid>.column:not(.row), .ui.stackable.grid>.row>.column, .ui.stackable.grid>.row>.wide.column, .ui.stackable.grid>.wide.column {
    width: 100% !important;
  }
}


.status-headercell {
  width: 75px;
}
.view-headercell {
  width: 50px;
}

.rec-org-link {
  display: block;
}